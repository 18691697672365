// Load all network if domain is exist the following below list

export const DOMAIN_WHITELIST = [
  'https://dev.trustlessbridge.io',
  'http://dev.trustlessbridge.io',
  'dev.trustlessbridge.io',

  'https://trustlessbridge.io',
  'http://trustlessbridge.io',
  'trustlessbridge.io',

  'https://testnet.trustlessbridge.io',
  'http://testnet.trustlessbridge.io',
  'testnet.trustlessbridge.io',

  'https://0.0.0.0:3000',
  'http://0.0.0.0:3000',
  'http://localhost:3000',
  'localhost:3000',
  '0.0.0.0:3000',
];
