import px2rem from '@/utils/px2rem';
import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';

const HEADER_HEIGHT = 80;
const FO0TER_HEIGHT = 80;

export const Container = styled.div`
  min-height: 100vh;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 0 ${px2rem(32)};
  /* background-color: black; */
`;

const ContentWrapper = styled.div`
  display: flex;
  min-height: 80vh;

  /* background-color: gray; */
`;

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Header height={HEADER_HEIGHT} />
      <ContentWrapper id="Super ContentWrapper">{children}</ContentWrapper>
      <Footer height={FO0TER_HEIGHT} />
    </Container>
  );
};

export const withLayout = (WrappedComponent: FC) => (props: any) => {
  return (
    <Layout>
      <WrappedComponent {...props}></WrappedComponent>
    </Layout>
  );
};

export default Layout;
