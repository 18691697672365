import { getTokenListAPI } from '@/services/tokens';
import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TokenState } from './reducer';
import { Token } from './types';
import { filterManager } from './filterManager';
import { RootState } from '..';
import { NETWORK_WHITE_LIST } from '@/constants/network';

export const PREDIX = 'TOKENS';

const setTokens: CaseReducer<TokenState, PayloadAction<Token[]>> = (
  state,
  action,
) => {
  state.tokenList = action.payload;
};

const setTokensByNewList: CaseReducer<TokenState, PayloadAction<Token[]>> = (
  state,
  action,
) => {
  state.tokenList = action.payload;
};

const fetchToken = createAsyncThunk(
  `${PREDIX}/fetchToken`,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const currentL2Network = state.networkReducer?.currentL2Network;

    let tokenList = await getTokenListAPI();

    if (currentL2Network) {
      tokenList =
        tokenList.filter(
          (token) =>
            token.network === currentL2Network ||
            NETWORK_WHITE_LIST.includes(token.network),
        ) || [];
    }

    return filterManager([...tokenList]) || [];
  },
);

const actionCreators = {
  setTokens,
  setTokensByNewList,
};

// Export Pure Actions
export { actionCreators };
// Export Async Actions
export { fetchToken };
