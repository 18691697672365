import { createAsyncThunk } from '@reduxjs/toolkit';
// import { transformNetwork } from './helper';

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { INetwork, NetworkReducerState } from './types';
import { getNetworkListAPI } from '@/services/networks';
import { RootState } from '..';
import { NETWORK_WHITE_LIST } from '@/constants/network';

export const PREDIX = 'NETWORKS';

const setNetworks: CaseReducer<NetworkReducerState, PayloadAction<INetwork[]>> = (
  state,
  action,
) => {
  state.networkList = action.payload;
};

const setNetworksByNewList: CaseReducer<
  NetworkReducerState,
  PayloadAction<INetwork[]>
> = (state, action) => {
  state.networkList = action.payload;
};

const setCurrentL2Network: CaseReducer<
  NetworkReducerState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.currentL2Network = action.payload;
};

const fetchNetworks = createAsyncThunk(
  `${PREDIX}/fetchNetworks`,
  async (_, { getState }) => {
    const state = getState() as RootState;
    const currentL2Network = state.networkReducer?.currentL2Network;

    let dataList = await getNetworkListAPI();

    if (currentL2Network) {
      dataList =
        dataList.filter(
          (network) =>
            network.networkName === currentL2Network ||
            NETWORK_WHITE_LIST.includes(network.networkName),
        ) || [];
    }

    return dataList;
  },
);

const actionCreators = {
  setNetworks,
  setNetworksByNewList,
  setCurrentL2Network,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export { fetchNetworks };
