import { DOMAIN_WHITELIST } from '@/constants/domain';
import Home from '@/containers/Home';
import { HeadComponent } from '@/metadata';
import { WebsiteConfigInit } from '@/metadata/constants';
import { fetcher } from '@/ssw/fetcher';
import { ServerSideProps } from '@/ssw/types';
import { setAppState } from '@/state/app/reducer';
import { appStateSelector } from '@/state/app/selector';
import { setWebsiteConfig } from '@/state/config/reducer';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { networkActionCreators } from '@/state/network/reducer';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import ErrorPage from './ErrorPage';

export async function getServerSideProps(context: any) {
  const data = await fetcher(context, true);
  return {
    props: data,
  };
}

export default function HomePage(props: ServerSideProps) {
  const { networkName, config, host, errorCodes } = props;

  console.log('------------------------------------------------------');
  console.log('HomePage props --- ', props);
  console.log('------------------------------------------------------');

  const dispatch = useAppDispatch();
  const { appState } = useAppSelector(appStateSelector);

  const isByPassValidate = useMemo(() => {
    return DOMAIN_WHITELIST.includes(host);
  }, []);

  const initData = () => {
    let currentL2Network;
    if (isByPassValidate) {
      currentL2Network = undefined; // --> Load All Network
    } else {
      currentL2Network = networkName;
    }

    dispatch(networkActionCreators.setCurrentL2Network(currentL2Network));
    dispatch(setWebsiteConfig(isEmpty(config) ? WebsiteConfigInit : config));

    //Save NetworkList into Redux Store
    // (TODO)

    //Save TokenList into Redux Store
    // (TODO)

    dispatch(setAppState('READY'));
  };

  useEffect(() => {
    initData();
  }, [errorCodes, config, networkName]);

  if (appState === 'INIT') return <></>;
  if (isByPassValidate || isEmpty(errorCodes))
    return (
      <>
        <HeadComponent {...config.metaConfig} />
        <Home />
      </>
    );
  return <ErrorPage errorMesage={'Something went wrong!'} errorCodes={errorCodes} />;
}
