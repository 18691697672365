/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { STRINGS } from '@/constants/strings';
import { RootState } from '@/state';
import { getIsAuthenticatedSelector } from '@/state/user/selector';
import { getWeb3InforSelector } from '@/state/web3React/selector';
import { formatAmountFactory, humanReadable } from '@/utils/format';
import {
  isLayer2,
  isOutChain,
  NETWORK_SUPPORTING,
  NETWORK_WHITE_LIST,
} from '@constants/network';
import {
  getTokenGroupNetworkSelector,
  getTokenMapSelector,
} from '@state/tokens/selector';
import { orderBy, intersection } from 'lodash';
import { createSelector } from 'reselect';
import { isLayer1Network } from '../FormBridge.utils';

import { GetNetworkListWithResult } from './types';
import { NetworkName } from '@/state/network/types';
import {
  getNetworkByNameSelector,
  getCurrentL2NetworkSelector,
} from '@/state/network/selector';
import { depositDataFormmater, estimateDataFormmater } from './helper';
import { ZERO_ADDRESS } from '@/constants/address';
import BigNumber from 'bignumber.js';

const formReducerSelector = (state: RootState) => state.formBridgeReducer;

// From Data

const getFromNetworkSelected = createSelector(
  formReducerSelector,
  (state) => state.fromNetworkSelected,
);
const getFromTokenSelected = createSelector(
  formReducerSelector,
  (state) => state.fromTokenSelected,
);
const getFromInputAmount = createSelector(
  formReducerSelector,
  (state) => state.fromNetworkSelected,
);

// To Data
const getToNetworkSelected = createSelector(
  formReducerSelector,
  (state) => state.toNetworkSelected,
);
const getToTokenSelected = createSelector(
  formReducerSelector,
  (state) => state.toTokenSelected,
);
const getToInputAddress = createSelector(
  formReducerSelector,
  (state) => state.toInputAddress,
);

const getFormType = createSelector(formReducerSelector, (state) => state.formType);

const getChainInfor = createSelector(
  [formReducerSelector, getWeb3InforSelector, getNetworkByNameSelector],
  (fromBridgeData, web3Data, getNetworkByNameFunc) => {
    const { fromNetworkSelected, formType } = fromBridgeData;

    let isCorrectChain = false;
    let supportChainID = -1;
    let supportChainName = undefined;

    const currentChainMetaMask = web3Data?.chainId;

    if (fromNetworkSelected) {
      switch (formType) {
        case 'Deposit':
          {
            //Deposit Centralize, not use metamask => TO DO
          }
          break;
        case 'Withdraw':
          {
            const networkObj = getNetworkByNameFunc(fromNetworkSelected);
            supportChainID = networkObj?.chainId || -1;
            supportChainName = networkObj?.networkTitle;
          }
          break;
        default:
          break;
      }
    }

    isCorrectChain = currentChainMetaMask === supportChainID;

    return {
      web3Data,
      isSwitchNetwork: !isCorrectChain,
      isCorrectChain,
      supportChainID,
      supportChainName,
      currentChainMetaMask,
    };
  },
);

const getEstimateWithdrawSelector = createSelector(formReducerSelector, (state) => {
  const { estimateWithdrawLoading, estimateWithdrawFetched, estimateWithdrawData } =
    state;

  return {
    isLoading: estimateWithdrawLoading,
    isFetched: estimateWithdrawFetched,
    data: estimateDataFormmater(estimateWithdrawData),
  };
});

const getSubmitBtnInfor = createSelector(
  [
    formReducerSelector,
    getChainInfor,
    getWeb3InforSelector,
    getIsAuthenticatedSelector,
    getEstimateWithdrawSelector,
  ],
  (fromBridgeData, chainInfor, web3Infor, isAuthen, estimateWithdrawData) => {
    const {
      fromNetworkSelected,
      formType = 'Deposit',
      toNetworkSelected,
      fromTokenSelected,
    } = fromBridgeData;

    const { isNeededConnectWeb3, isActivating, isActive } = web3Infor;
    const { data: estimateData } = estimateWithdrawData;

    const { isSwitchNetwork, isCorrectChain, supportChainName } = chainInfor;

    let submitBtnTitle = 'Transfer';
    let disableSubmitBtn = false;
    if (!fromNetworkSelected) {
      disableSubmitBtn = true;
    } else {
      if (formType === 'Deposit') {
        if (isNeededConnectWeb3) {
          submitBtnTitle = 'Connect Wallet';
        } else {
          if (isActivating) {
            submitBtnTitle = 'Connecting...';
          }
          if (isActive) {
            if (fromNetworkSelected && toNetworkSelected) {
              submitBtnTitle =
                isCorrectChain && !isSwitchNetwork
                  ? 'Transfer'
                  : `Switch to ${supportChainName}`;
            } else {
              disableSubmitBtn = true;
            }
          }
        }
      } else {
        if (
          toNetworkSelected === NETWORK_SUPPORTING.BITCOIN &&
          fromTokenSelected?.type?.toLowerCase() === 'brc20' &&
          estimateData?.isNeedBTCBalanceMore
        ) {
          disableSubmitBtn = true;
        }
        if (isNeededConnectWeb3) {
          submitBtnTitle = 'Connect MetaMask';
        } else if (
          !isAuthen &&
          fromNetworkSelected &&
          isLayer1Network(fromNetworkSelected)
        ) {
          submitBtnTitle = 'Connect Trustless Wallet';
        } else {
          submitBtnTitle =
            isCorrectChain && !isSwitchNetwork
              ? 'Transfer'
              : `Switch to ${supportChainName}`;
        }
      }
    }

    const result = {
      ...web3Infor,
      submitBtnTitle,
      disableSubmitBtn,
      isNeededConnectWeb3,
    };

    return result;
  },
);

const getFormTypeSelector = createSelector(
  [formReducerSelector],
  (formBridgeData) => {
    const { formType } = formBridgeData;
    const isDepositForm = formType === 'Deposit';
    const isWithdrawForm = formType === 'Withdraw';
    return {
      formType,
      isDepositForm,
      isWithdrawForm,
    };
  },
);
const getInputDecorateSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { formType, toNetworkSelected } = fromBridgeData;

    let disableToNetworkSelect = false;
    let toInputPlaceHolder = STRINGS.paste_tc_address;

    //Deposit Centralize via BE service
    if (formType === 'Deposit') {
      toInputPlaceHolder = `${STRINGS.pasteReceivingAddress}`;
    }

    //Otherwise is Withdraw
    else {
      //Output is Bitcoin Network
      if (
        toNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
        toNetworkSelected === NETWORK_SUPPORTING.ORDINALS
      ) {
        toInputPlaceHolder = `${STRINGS.pasteBTCAddress}`;
      }

      //Output is Ethereum Network
      else if (toNetworkSelected === NETWORK_SUPPORTING.ETHEREUM) {
        toInputPlaceHolder = `${STRINGS.pasteETHAddress}`;
      }
      //Output is Another (NOS, L1, L2, L2' ....) Network
      else {
        toInputPlaceHolder = `${STRINGS.pasteReceivingAddress}`;
      }
    }

    const result = {
      disableToNetworkSelect,
      toInputPlaceHolder,
    };

    return result;
  },
);

const getFormBridgeInfo = createSelector(
  [
    formReducerSelector,
    getChainInfor,
    getSubmitBtnInfor,
    getFormTypeSelector,
    getNetworkByNameSelector,
    getInputDecorateSelector,
  ],
  (
    fromBridgeData,
    chainInfor,
    submitBtnInfo,
    formTypeData,
    getNetworkByNameFunc,
    inputDecorateData,
  ) => {
    const {
      toNetworkSelected,
      fromTokenSelected,
      formType,
      fromBalance,
      fromNetworkSelected,
    } = fromBridgeData;

    const { isSwitchNetwork } = chainInfor;

    let isL1ToL2 =
      isLayer1Network(fromNetworkSelected) && isLayer2(toNetworkSelected);
    let isL2ToL1 =
      isLayer1Network(toNetworkSelected) && isLayer2(fromNetworkSelected);

    let isNativeToken;
    const isBrc20 =
      fromTokenSelected && fromTokenSelected.type?.toUpperCase() === 'BRC20';
    const isBTCToken =
      fromTokenSelected &&
      fromTokenSelected.symbol?.toUpperCase() === 'BTC' &&
      fromTokenSelected.name?.toUpperCase() === 'BITCOIN';
    if (fromTokenSelected?.tokenID === ZERO_ADDRESS) {
      isNativeToken = true;
    } else if (formType === 'Withdraw') {
      if (isOutChain(fromNetworkSelected)) {
        isNativeToken = fromTokenSelected?.type === 'NATIVE';
      } else {
        isNativeToken = fromTokenSelected?.isNativeBridge;
      }
    } else {
      isNativeToken =
        fromTokenSelected && fromTokenSelected.type?.toUpperCase() === 'NATIVE';
    }

    isNativeToken = !!isNativeToken;

    const fromBalanceFormatPrice = formatAmountFactory(
      fromBalance || '0.0',
      formType === 'Withdraw' ? 18 : fromTokenSelected?.decimals,
    );
    const {
      priceBN: fromBalanceBN,
      priceFormatedBN: fromBalanceFormatedBN,
      priceFormatedStr: fromBalanceFormatedStr,
    } = fromBalanceFormatPrice;

    const fromNetworkSelectedNew = fromNetworkSelected ?? NETWORK_SUPPORTING.BITCOIN;
    const toNetworkSelectedNew =
      toNetworkSelected ?? NETWORK_SUPPORTING.TRUSTLESS_LAYER1;

    const fromNetworkObject = getNetworkByNameFunc(fromNetworkSelectedNew);
    const toNetworkObject = getNetworkByNameFunc(toNetworkSelectedNew);

    const isCrossLayer = !isOutChain(toNetworkSelectedNew);

    let isBurnNativeToken = false;
    let isTransferBVMFromL1ToNOS =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER2 &&
      fromTokenSelected?.symbol?.toUpperCase() === 'BVM';

    let isTransferBVMFromNOSToL1 =
      fromNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER2 &&
      toNetworkSelected === NETWORK_SUPPORTING.TRUSTLESS_LAYER1 &&
      fromTokenSelected?.symbol?.toUpperCase() === 'BVM';

    if (isNativeToken && isOutChain(toNetworkSelected) && formType === 'Withdraw') {
      isBurnNativeToken = true;
    }

    const tokenSymbol = fromTokenSelected?.symbol?.toUpperCase();

    const result = {
      ...fromBridgeData,
      ...submitBtnInfo,
      ...chainInfor,
      ...formTypeData,
      ...inputDecorateData,

      fromNetworkSelected: fromNetworkSelectedNew,
      toNetworkSelected: toNetworkSelectedNew,
      fromNetworkObject,
      toNetworkObject,

      isNativeToken,
      isSwitchNetwork,

      isBrc20,
      isBTCToken,

      fromBalanceBN,
      fromBalanceFormatedBN,
      fromBalanceFormatedStr,

      isL1ToL2,
      isL2ToL1,
      isCrossLayer,
      isBurnNativeToken,
      isTransferBVMFromL1ToNOS,
      isTransferBVMFromNOSToL1,

      tokenSymbol,
    };

    return result;
  },
);

const getFromBalanceSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { fromBalance } = fromBridgeData;

    const fromBalanceFormatPrice = formatAmountFactory(
      fromBalance || '0.0',
      18, //Hard code, TC always decimals = 18
    );
    const {
      priceBN: fromBalanceBN,
      priceFormatedBN: fromBalanceFormatedBN,
      priceFormatedStr: fromBalanceFormatedStr,
    } = fromBalanceFormatPrice;

    const result = {
      fromBalanceBN,
      fromBalanceFormatedBN,
      fromBalanceFormatedStr,
    };
    return result;
  },
);

const getGenerateDepositSelector = createSelector(formReducerSelector, (state) => {
  const {
    generateDepositData,
    generateDepositDataLoading,
    generateDepositDataFetched,
  } = state;

  return {
    isLoading: generateDepositDataLoading,
    isFetched: generateDepositDataFetched,
    data: depositDataFormmater(generateDepositData),
  };
});

const getFromInstanceSelector = createSelector(
  [formReducerSelector],
  (fromBridgeData) => {
    const { formInstance } = fromBridgeData;
    return formInstance;
  },
);

const getEstimateTimeProcess = createSelector(
  [getFormBridgeInfo],
  (fromBridgeInfo) => {
    const {
      fromTokenSelected,
      fromNetworkSelected,
      toNetworkSelected,
      formType,
      isL1ToL2,
      isL2ToL1,
    } = fromBridgeInfo;

    if (!formType || !fromNetworkSelected || !fromTokenSelected) return null;

    let estTime = 0;

    const isNativeBridge =
      fromTokenSelected.isNativeBridge || fromTokenSelected.type === 'NATIVE';

    switch (formType) {
      case 'Deposit':
        switch (fromNetworkSelected) {
          // BTC | Ordinal -> L1 | L2
          case NETWORK_SUPPORTING.BITCOIN:
          case NETWORK_SUPPORTING.ORDINALS:
            estTime = 40;
            break;

          // ETH ->  L1 | L2
          case NETWORK_SUPPORTING.ETHEREUM:
            estTime = 30;
            break;
          default:
            estTime = 0;
        }
        break;
      case 'Withdraw':
        {
          const symbol = fromTokenSelected.symbol?.toUpperCase() || 'UNKNOWN';
          if (isL1ToL2) {
            if (
              symbol === 'WETH' ||
              symbol === 'ETH' ||
              fromTokenSelected.type === 'ERC20'
            ) {
              estTime = 80;
            } else {
              if (isNativeBridge) {
                estTime = 60;
              } else {
                estTime = 90;
              }
            }
          } else if (isL2ToL1) {
            if (
              symbol === 'WETH' ||
              symbol === 'ETH' ||
              fromTokenSelected.type === 'ERC20'
            ) {
              estTime = 165;
            } else {
              if (isNativeBridge) {
                estTime = 0;
              } else {
                estTime = 175;
              }
            }
          } else {
            switch (fromNetworkSelected) {
              case NETWORK_SUPPORTING.TRUSTLESS_LAYER1:
                switch (toNetworkSelected) {
                  // L1 -> Ordinal
                  case NETWORK_SUPPORTING.ORDINALS:
                    estTime = 120;
                    break;

                  // L1 -> BTC | ETH
                  case NETWORK_SUPPORTING.BITCOIN:
                  case NETWORK_SUPPORTING.ETHEREUM:
                    estTime = 70;
                    break;
                  default:
                    estTime = 0;
                }
                break;
              default:
                switch (toNetworkSelected) {
                  // L2 -> Ordinal
                  case NETWORK_SUPPORTING.ORDINALS:
                    estTime = 165;
                    break;

                  // L2 -> BTC | ETH
                  case NETWORK_SUPPORTING.BITCOIN:
                    estTime = 145;
                    break;
                  case NETWORK_SUPPORTING.ETHEREUM:
                    estTime = 135;
                    break;
                  default:
                    estTime = 145;
                }
                break;
            }
          }
        }
        break;
      default:
        estTime = 135;
        break;
    }

    const unit = estTime > 1 ? 'mins' : 'min';
    const estTimeStr = `${estTime} ${unit}`;

    return {
      estTime,
      estTimeStr,
    };
  },
);

const getNetworkListAvailableSelector = createSelector(
  getTokenGroupNetworkSelector,
  (tokenGroupNetwork): NetworkName[] => {
    return (Object.keys(tokenGroupNetwork) as NetworkName[]) || [];
  },
);

const getTokensSelectable = createSelector(
  getTokenGroupNetworkSelector,
  getFormBridgeInfo,
  getNetworkListAvailableSelector,
  getCurrentL2NetworkSelector,
  (tokenGroupNetwork, getFormBridge, networkAvaible, currentL2) =>
    ({ isFrom }: { isFrom: boolean }) => {
      const { fromNetworkSelected, toNetworkSelected } = getFormBridge;
      if (isFrom && !fromNetworkSelected) return [];

      let tokens =
        tokenGroupNetwork[isFrom ? fromNetworkSelected : toNetworkSelected];

      if (tokens && currentL2 && networkAvaible) {
        tokens =
          tokens.filter((token) => {
            // All network  that token can be bridge
            let bridgeAddressList = Object.keys(token.bridgeAddress);

            // Continue, filter network FROM
            bridgeAddressList = bridgeAddressList.filter(
              (network) => network !== fromNetworkSelected,
            );

            // Intersection between networkAvaibleList & bridgeAddressList
            let networkExistBridge = intersection(networkAvaible, bridgeAddressList);

            if (networkExistBridge && networkExistBridge.length > 0) return true;
            else return false;
          }) || [];
      }

      if (
        fromNetworkSelected === NETWORK_SUPPORTING.BITCOIN ||
        fromNetworkSelected === NETWORK_SUPPORTING.ORDINALS
      ) {
        tokens = orderBy(
          tokens,
          (token) => [
            token.symbol?.toUpperCase() === 'BTC',
            token.network === NETWORK_SUPPORTING.BITCOIN,
          ],
          ['desc'],
        );
      } else if (fromNetworkSelected === NETWORK_SUPPORTING.ETHEREUM) {
        tokens = orderBy(
          tokens,
          (token) => [
            token.symbol?.toUpperCase() === 'ETH',
            token.symbol?.toUpperCase() === 'USDT',
            token.symbol?.toUpperCase() === 'USDC',
            token.network === NETWORK_SUPPORTING.ETHEREUM,
          ],
          ['desc'],
        );
      } else {
        tokens = orderBy(
          tokens,
          (token) => [
            token.symbol?.toUpperCase() === 'BVM',
            token.symbol?.toUpperCase() === 'BTC',
            token.symbol?.toUpperCase() === 'ETH',
            token.symbol?.toUpperCase() === 'USDT',
            token.symbol?.toUpperCase() === 'USDC',
          ],
          ['desc'],
        );
      }

      return tokens;
    },
);

const getDefaultTokensSelectable = createSelector(
  getTokensSelectable,
  getFormBridgeInfo,
  (getTokensSelectableFunc, fromBridgeInfo) => {
    const selectableTokenList = getTokensSelectableFunc({
      isFrom: true,
    });
    const { isL1ToL2, isL2ToL1, fromTokenSelected, fromNetworkSelected } =
      fromBridgeInfo;

    let fromTokenTmp;
    selectableTokenList.map((token) => {
      if (
        token.network === fromNetworkSelected &&
        token.symbol?.toUpperCase() === fromTokenSelected?.symbol?.toUpperCase()
      ) {
        fromTokenTmp = token;
        return;
      }
    });

    return fromTokenTmp ?? selectableTokenList[0] ?? undefined;
  },
);

const getNetworksSelectable = createSelector(
  getTokenGroupNetworkSelector,
  getFormBridgeInfo,
  getNetworkListAvailableSelector,
  (
    tokenGroupNetwork,
    getFormBridge,
    networkListAvailabel,
  ): GetNetworkListWithResult => {
    const { fromNetworkSelected, fromTokenSelected } = getFormBridge;

    let fromNetworkList = networkListAvailabel.filter(
      (networkName) => networkName != fromNetworkSelected,
    );

    let toNetworkList: NetworkName[] = [];

    if (fromTokenSelected) {
      const { tokenAddress } = fromTokenSelected;
      if (tokenAddress) {
        const networks = Object.keys(tokenAddress) as NetworkName[];
        for (const network of networks) {
          if (
            tokenAddress[network] &&
            typeof tokenAddress[network] === 'string' &&
            tokenAddress[network].length > 1 &&
            networkListAvailabel.includes(network) &&
            network !== fromNetworkSelected
          ) {
            toNetworkList.push(network as NetworkName);
          }
        }
      }
    }

    fromNetworkList = orderBy(
      fromNetworkList,
      (network) => [
        network === NETWORK_SUPPORTING.BITCOIN,
        network === NETWORK_SUPPORTING.ETHEREUM,
        network === NETWORK_SUPPORTING.TRUSTLESS_LAYER1,
        network === NETWORK_SUPPORTING.TRUSTLESS_LAYER2,
      ],
      ['desc', 'desc'],
    );

    toNetworkList = orderBy(
      toNetworkList,
      (network) => [
        network === NETWORK_SUPPORTING.TRUSTLESS_LAYER1,
        network === NETWORK_SUPPORTING.TRUSTLESS_LAYER2,
      ],
      ['desc', 'desc'],
    );

    return {
      fromNetworkList,
      toNetworkList,
    };
  },
);

const getMaxBalanceSelector = createSelector(
  [getFormBridgeInfo, getFromBalanceSelector],
  (fromBridgeData, maxBalanceObj) => {
    const {
      fromBalanceFormatedStr: depositBalance,
      fromTokenSelected,
      isSwitchNetwork,
      formType,
    } = fromBridgeData;

    const { fromBalanceFormatedStr: withdrawBalance } = maxBalanceObj;

    let maxBalance;

    if (!fromTokenSelected || isSwitchNetwork) {
      maxBalance = '0';
    } else if (formType === 'Deposit') {
      maxBalance = depositBalance;
    } else if (formType === 'Withdraw') {
      maxBalance = withdrawBalance;
    } else {
      maxBalance = '0';
    }

    return maxBalance;
  },
);

const getMaxErrorSelector = createSelector([formReducerSelector], (reducer) => {
  return reducer.error;
});

const getToTokenSelectedSelector = createSelector(
  [getFormBridgeInfo, getTokenMapSelector],
  (formBridgeInfo, tokenMap) => {
    const { fromTokenSelected, toNetworkSelected } = formBridgeInfo;
    if (!fromTokenSelected || !toNetworkSelected) return undefined;
    const key = `[${toNetworkSelected}]-[${fromTokenSelected.tokenAddress[toNetworkSelected]}]`;
    return tokenMap[key];
  },
);

const getFeeBurnNativeTokenSelector = createSelector(
  formReducerSelector,
  (formState) => {
    const { feeBurnNativeToken } = formState;
    const dataFormated = formatAmountFactory(
      feeBurnNativeToken || '0',
      18,
      BigNumber.ROUND_CEIL,
    );
    return {
      feeBurnNativeToken_BN: dataFormated.priceBN,
      feeBurnNativeTokenFormated_BN: dataFormated.priceFormatedBN,
      feeBurnNativeTokenFormated_Str: humanReadable(
        dataFormated.priceFormatedStr || '0',
        9,
      ),
    };
  },
);

export {
  formReducerSelector,
  getChainInfor,
  getDefaultTokensSelectable,
  getEstimateTimeProcess,
  getEstimateWithdrawSelector,
  getFormBridgeInfo,
  //
  getFormType,
  getFromBalanceSelector,
  getFromInputAmount,
  //
  getFromInstanceSelector,
  // From Data
  getFromNetworkSelected,
  getFromTokenSelected,
  getGenerateDepositSelector,
  getNetworksSelectable,
  getSubmitBtnInfor,
  getToInputAddress,
  // To Data
  getToNetworkSelected,
  getToTokenSelected,
  //
  getTokensSelectable,
  //Max Balance
  getMaxBalanceSelector,
  getMaxErrorSelector,
  //
  getToTokenSelectedSelector,
  //Burn NativeToken
  getFeeBurnNativeTokenSelector,
  getNetworkListAvailableSelector,
};
