import { NextPage } from 'next';

import styled, { DefaultTheme } from 'styled-components';
import { Info } from 'react-feather';

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100dvw;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    align-items: center;
    align-self: center;
    letter-spacing: -0.02em;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.white};
  }

  .desc {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    align-items: center;
    align-self: center;
    letter-spacing: -0.02em;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.white};
  }
`;

const ErrorPage: NextPage<{
  errorMesage?: string;
  errorCodes?: number[];
}> = (props) => {
  const { errorMesage, errorCodes } = props;
  return (
    <Container>
      <Info
        color="white"
        style={{
          width: 50,
          height: 50,
        }}
      />
      <p className="title">{errorMesage ? errorMesage : 'Page not found'}</p>
      <p className="desc">
        {errorCodes?.map((errCode) => {
          return `Error Code: [${errCode}]`;
        })}
      </p>
    </Container>
  );
};

export default ErrorPage;
