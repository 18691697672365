import { CDN_URL } from '@/configs';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { Banner, Wrapper } from './Header.styled';
import MenuMobile from './MenuMobile';
import WalletHeader from './Wallet';
import { useRouter } from 'next/router';
import { useConfig } from '@/state/config/hooks';

const Header = ({ height }: { height: number }) => {
  const router = useRouter();
  const { metaConfig, themeConfig } = useConfig();
  const refMenu = useRef<HTMLDivElement | null>(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    if (refMenu.current) {
      if (isOpenMenu) {
        gsap.to(refMenu.current, { x: 0, duration: 0.6, ease: 'power3.inOut' });
      } else {
        gsap.to(refMenu.current, {
          x: '100%',
          duration: 0.6,
          ease: 'power3.inOut',
        });
      }
    }
  }, [isOpenMenu]);

  return (
    <>
      <Wrapper style={{ height }}>
        <div className="indicator" />
        <div
          className="logo"
          onClick={() => {
            window.open(window.location.origin, '_self');
            // router.push(ROUTE_PATH.HOME);
          }}
        >
          <img
            src={metaConfig.favIconUrl || `${CDN_URL}/images/names-logo.svg`}
            width={48}
            height={48}
            style={{
              maxHeight: '48px',
              maxWidth: '48px',
            }}
          />
          <p className="logo-title">{metaConfig.tabTitle || 'Trustless Bridge'}</p>
        </div>
        <MenuMobile ref={refMenu} onCloseMenu={() => setIsOpenMenu(false)} />
        <div className="rightContainer">
          <WalletHeader />
          <button className="btnMenuMobile" onClick={() => setIsOpenMenu(true)}>
            <img src={`${CDN_URL}/icons/ic_hambuger.svg`} />
          </button>
        </div>
      </Wrapper>
    </>
  );
};

export default Header;
