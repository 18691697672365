import { RootState } from '@/state';
import { createSelector } from 'reselect';
import { NetworkName } from './types';

export const reducerSelector = (state: RootState) => state.networkReducer;

export const getNetworkListSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.networkList || [],
);

export const isFetchedNetworkSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.isFetched,
);

export const getNetworkNameListSelector = createSelector(
  getNetworkListSelector,
  (networkList) => {
    return networkList
      .map((network) => network.networkName)
      .filter((name) => !!name);
  },
);

// Result
//  chainID
//  Otherwise:  -1 network not found => return chainID = -1
export const getChainIDByNetworkNameSelector = createSelector(
  getNetworkListSelector,
  (networkList) => (networkName: NetworkName) => {
    const networkFinded = networkList.find(
      (network) => networkName === network.networkName,
    );
    return networkFinded ? networkFinded.chainId : -1;
  },
);

// Result
//  networkFinded
//  undefined (not found)
export const getNetworkByNameSelector = createSelector(
  getNetworkListSelector,
  (networkList) => (networkName: NetworkName) => {
    const networkFinded = networkList.find(
      (network) => networkName === network.networkName,
    );
    return networkFinded;
  },
);

export const getNetworkInforSelector = createSelector(
  [reducerSelector, getNetworkNameListSelector],
  (state, networkList) => {
    const result = {
      ...state,
      networkList,
    };

    return result;
  },
);

export const getCurrentL2NetworkSelector = createSelector(
  reducerSelector,
  (reducer) => reducer.currentL2Network,
);
